/// http request procoss functions
import axios, { AxiosError } from "axios";
import TokenService from "../store/token";
import { signUser } from "../store";
import { IToken, SignDto } from "../models/Dto";
import { Admin } from "../models/Admin";
import { Member, UserPrivilegeType } from "../models/Member";
import { setRecoil } from "recoil-nexus";
import { SwearWord } from "../models/Swearword";

const backend_baseUrl = process.env.REACT_APP_BACKEND_URL;
const apiClient = axios.create({
  baseURL: `${backend_baseUrl}/api`,
});
const uploadClient = axios.create({
  baseURL: `${backend_baseUrl}/api`,
});
/// common axios object with jwt token
apiClient.interceptors.request.use(async (config) => {
  const token = TokenService.getLocalAccessToken();
  let sToken = "";
  try {
    if (token) sToken = JSON.parse(token);
  } catch (er) {
    sToken = token ?? "";
  }
  if (config && config.headers && sToken) {
    // config.headers = { ...config.headers };
    config.headers["Authorization"] = `Bearer ${sToken}`;
  }
  if (config.headers) config.headers["Content-Type"] = "application/json";
  return config;
});
///  uploading axios  object with jwt token
uploadClient.interceptors.request.use(async (config) => {
  const token = TokenService.getLocalAccessToken();
  let sToken = "";
  try {
    if (token) sToken = JSON.parse(token);
  } catch (er) {
    sToken = token ?? "";
  }
  if (config && config.headers && sToken) {
    // config.headers = { ...config.headers };
    config.headers["Authorization"] = `Bearer ${sToken}`;
  }
  if (config.headers) config.headers["Content-Type"] = "multipart/form-data";
  return config;
});
/// when authorization was failed , you would be logouted.
apiClient.interceptors.response.use(
  async (resp) => resp,
  (err: AxiosError) => {
    if (err.response?.status === 401) {
      TokenService.updateLocalAccessToken("");
      setRecoil(signUser, undefined);
    }
    return Promise.reject(err);
  }
);
export const googleSignup = async (formData: string) => {
  const response = await apiClient.post("Admin/signup", formData);
  return response.data;
};
export const Signup = async (formData: string) => {
  const response = await apiClient.post("Sign/signup", formData);
  return response.data as SignDto;
};
export const SigninUser = async (formData: string) => {
  const response = await apiClient.post("Admin/login", formData);
  return response.data as SignDto;
};
export const saveAdminUser = async (formData: string) => {
  const response = await apiClient.post("Admin/save_admin", formData);
  return response.data as SignDto;
};
export const saveMemberUser = async (formData: string) => {
  const response = await apiClient.post("Admin/save_member_info", formData);
  return response.data as any;
};
export const getAdminList = async () => {
  const response = await apiClient.get<Admin[]>("Admin/admin_list");
  return response.data;
};
export const getUserList = async () => {
  const response = await apiClient.get<Member[]>("Admin/user_list");
  return response.data;
};
export const synToken = async () => {
  const response = await apiClient.get<IToken>(`Admin/syn_token`);
  return response.data;
};
export const saveUserPType = async (formData: string) => {
  const response = await apiClient.post("Asset/save_privilege_info", formData);
  return response.data as SignDto;
};
export const saveSwearword = async (formData: string) => {
  const response = await apiClient.post("Asset/save_swearword_data", formData);
  return response.data as SignDto;
};
export const getUserTypes = async () => {
  const response = await apiClient.get<UserPrivilegeType[]>(
    "Asset/user_privilege_type_list"
  );
  return response.data;
};
export const getSwearwords = async () => {
  const response = await apiClient.get<SwearWord[]>("Asset/get_swearword_list");
  return response.data;
};
export const deleteUser = async (member_id: string) => {
  const response = await apiClient.delete<any>(
    `Asset/delete_user/${member_id}`
  );
  return response.data;
};
