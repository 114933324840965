import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { signUser } from '../store';
import { useRecoilState } from 'recoil';
import logo_icon from '../Assets/images/logo.png'
export interface ILinkProps {
  title: string;
  link: string;
  selected: string;
  isCurOpen?: boolean;
  decorationCls?: string;
  goLink?: () => void;
}
export const Header = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const curLinkCss =
    'text-[#3FC6AD] font-bold underline underline-offset-[6px] decoration-4';
  const mobile_bg =
    pathname == '/' ? 'max-md:home_mb_bg' : 'max-md:bg-[#2B574F]';
  const commonLinkCss = 'text-[#2B2B2B]';
  const [sUser, setSignUser] = useRecoilState(signUser);

  return (
    <header
      className={`md:sticky md:top-0 h-[50px] header_shadow flex items-center justify-between px-4 `}
    >
      <div className="my-4">
        <img src={logo_icon} alt="" width={150}/>
      </div>
      <button
        className="button"
        onClick={() => {
          setSignUser(undefined);
        }}
      >
        Logout
      </button>
    </header>
  );
};
