import { Header, ILinkProps } from './Header';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { SideMenu } from './SideMenu';
import { UseSynToken } from '../services/Query';
export const LinkFooterItem = ({ title, link, selected }: ILinkProps) => {
  return (
    <div className="flex items-center justify-center">
      <Link
        className={`${
          selected === link ? 'font-bold  ' : ''
        } text-lg md:text-base`}
        to={link}
      >
        {title}
      </Link>
    </div>
  );
};
export const Layout = () => {
  const { data } = UseSynToken();
  return (
    <main>
      <Header />
      <div className="flex  w-full ">
        <div>
          <SideMenu />
        </div>
        <div className="ps-[50px]">
          <Outlet />
        </div>
      </div>
    </main>
  );
};
