import { Link, useLocation } from 'react-router-dom';

export const SideMenu = () => {
  const { pathname } = useLocation();
  const menuCls = (url: string) => {
    return pathname === url ? ' font-bold' : '';
  };
  return (
    <div className={'w-[220px] border-l-0 border border-y-0 menu_h shadow-md'}>
      <div className="grid py-4 gap-y-3">
        <div>
          <Link to={'/admin'} className={`${menuCls('/admin')} px-3`}>
            Admins
          </Link>
        </div>
        <div>
          <Link to={'/users'} className={`${menuCls('/users')} px-3`}>
            Users
          </Link>
        </div>
        {/* <div>
          <Link to={'/user_types'} className={`${menuCls('/user_types')} px-3`}>
            User Privilege
          </Link>
        </div> */}
        <div>
          <Link to={'/swearword'} className={`${menuCls('/swearword')} px-3`}>
            Swear words
          </Link>
        </div>
      </div>
    </div>
  );
};
