import React, { Suspense, lazy } from 'react';
import './App.css';
import ReactLoading from 'react-loading';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Layout } from './components/Layout';
import { QueryClient, QueryClientProvider } from 'react-query';
import { signUser } from './store';
import { useRecoilValue } from 'recoil';
const Signin = lazy(() =>
  import('./pages/Signin').then((module) => ({
    default: module.Signin,
  }))
);
const AdminUsers = lazy(() =>
  import('./pages/AdminUsers').then((module) => ({
    default: module.AdminUsers,
  }))
);
const Users = lazy(() =>
  import('./pages/Users').then((module) => ({
    default: module.Users,
  }))
);
const UserTypes = lazy(() =>
  import('./pages/UserTypes').then((module) => ({ default: module.UserTypes }))
);
const Swearword = lazy(() =>
  import('./pages/Swearword').then((module) => ({ default: module.Swearword }))
);
const LoadStatus = () => {
  return (
    <div className="w-full py-4 text-center">
      <ReactLoading
        type="spinningBubbles"
        className="mx-auto my-auto"
        height={'70px'}
        width={'70px'}
        color="#666666"
      />
    </div>
  );
};
function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 1000 * 120, // 30seconds
        refetchOnMount: 'always',
      },
    },
  });
  const userInfo = useRecoilValue(signUser);
  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<LoadStatus />}>
        <BrowserRouter>
          <Routes>
            <Route path="/signin" element={<Signin />} />
            {userInfo && (
              <Route path="/" element={<Layout />}>
                <Route index path="admin" element={<AdminUsers />} />
                <Route index path="users" element={<Users />} />
                <Route index path="user_types" element={<UserTypes />} />
                <Route index path="swearword" element={<Swearword />} />
                {/* <Route path="team" element={<Team />} />
            <Route path="contact" element={<Contact />} />
            <Route path="framework" element={<Framework />} /> */}
              </Route>
            )}
            <Route path="*" element={<Signin />} />
          </Routes>
        </BrowserRouter>
      </Suspense>
    </QueryClientProvider>
  );
}

export default App;
