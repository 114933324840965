import { atom } from 'recoil';
import { Admin } from '../models/Admin';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const signUser = atom<Admin | undefined>({
  key: 'singin_user',
  default: undefined,
  effects_UNSTABLE: [persistAtom],
});
