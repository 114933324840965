import { useMutation, useQuery } from "react-query";
import { Admin } from "../models/Admin";
import {
  SigninUser,
  deleteUser,
  getAdminList,
  getSwearwords,
  getUserList,
  getUserTypes,
  googleSignup,
  saveAdminUser,
  saveMemberUser,
  saveSwearword,
  saveUserPType,
  synToken,
} from "./HttpServices";
import TokenService from "../store/token";
import { SignDto } from "../models/Dto";
import { Member, UserPrivilegeType } from "../models/Member";
import { SwearWord } from "../models/Swearword";

export const useLogin = () => {
  return useMutation<Admin, Error, string>(
    "login",
    async (data) => {
      return await googleSignup(data);
    },
    {
      retry: false,
    }
  );
};

export const UseSignin = () => {
  return useMutation<SignDto, Error, string>(
    ["signin"],
    async (data) => {
      return await SigninUser(data);
    },
    {
      retry: false,
    }
  );
};
export const UseSaveAdminInfo = () => {
  return useMutation<any, Error, string>(
    ["save_admin_infor"],
    async (data) => {
      return await saveAdminUser(data);
    },
    {
      retry: false,
    }
  );
};
export const UseSaveMemberInfo = () => {
  return useMutation<any, Error, string>(
    ["save_member_infor"],
    async (data) => {
      return await saveMemberUser(data);
    },
    {
      retry: false,
    }
  );
};
export const UseUserTypeInfo = () => {
  return useMutation<any, Error, string>(
    ["save_user_type_info"],
    async (data) => {
      return await saveUserPType(data);
    },
    {
      retry: false,
    }
  );
};
export const UseSwearwordData = () => {
  return useMutation<any, Error, string>(
    ["save_swear_word_data"],
    async (data) => {
      return await saveSwearword(data);
    },
    {
      retry: false,
    }
  );
};
export const UseAdminList = () => {
  return useQuery<Admin[] | undefined, Error>(
    ["get_all_admin"],
    async () => {
      return await getAdminList();
    },
    {
      retry: false,
    }
  );
};
export const UseUserTypes = () => {
  return useQuery<UserPrivilegeType[] | undefined, Error>(
    ["get_user_privilege_types"],
    async () => {
      return await getUserTypes();
    },
    {
      retry: false,
    }
  );
};
export const UseSwearword = () => {
  return useQuery<SwearWord[] | undefined, Error>(
    ["get_swear_word"],
    async () => {
      return await getSwearwords();
    },
    {
      retry: false,
    }
  );
};
export const UseUserList = () => {
  return useQuery<Member[] | undefined, Error>(
    ["get_all_members"],
    async () => {
      return await getUserList();
    },
    {
      retry: false,
    }
  );
};
export const UseSynToken = () => {
  return useQuery<any, Error>(
    ["get_syn_token"],
    async () => {
      const res = await synToken();
      if (res) TokenService.updateLocalAccessToken(res.jwt_token);
      return res;
    },
    {
      retry: false,
      refetchInterval: 10 * 60 * 1000,
      refetchIntervalInBackground: true,
    }
  );
};
export const UseDeleteUser = () => {
  return useMutation<any, Error, string>(
    ["delete_user"],
    async (member_id) => {
      return await deleteUser(member_id);
    },
    {
      retry: false,
    }
  );
};
